<template>
  <div v-loading="loading">
    <div class="table-area">
      <el-row class="table-top">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <h4 class="heading">Settings</h4>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="btn-wrapper">
            <el-button type="primary" @click="handleCarryForward()">Carry Forward</el-button>
            <!-- <el-button type="primary" @click="handleGenerateBill()">Generate Bill</el-button> -->
            <el-button type="primary" @click="handleSettlement()">Settlement</el-button>
            <el-button type="primary" @click="handleUpdate()">Update</el-button>
            
          </div>
          <div class="setting-content">
            <p>Carry froward completed {{ viewData.carry_froward_done_percentage }} %</p> | 
            <p>Settlement completed {{ viewData.settlement_done_percentage }} %</p>
          </div>
        </el-col>
      </el-row>

      <div>
        <el-card class="setting_wrapper">
          <div class="table_wrapper">
            <table class="table" border="1" width="100%" cellspacing="0">
              <tbody>
                <tr>
                  <th><p>Day</p></th>
                  <th><p>MCX open time</p></th>
                  <th><p>MCX close time</p></th>
                  <th><p>NSE open time</p></th>
                  <th><p>NSE close time</p></th>
                  <th><p>Forex open time</p></th>
                  <th><p>Forex close time</p></th>
                </tr>
                <tr v-for="(d, index) in viewData.market_timings" :key="index">
                  <td>
                    <p>{{ d.day_display }}</p> 
                  </td>
                  <td>
                    <p>{{ formateTime(d.mcx_open_time) }}</p> 
                  </td>
                  <td>
                    <p>{{ formateTime(d.mcx_close_time) }}</p> 
                  </td>
                  <td>
                    <p>{{ formateTime(d.nse_open_time) }}</p> 
                  </td>
                  <td>
                    <p>{{ formateTime(d.nse_close_time) }} </p>
                  </td>
                  <td>
                    <p>{{ formateTime(d.forex_open_time) }} </p>
                  </td>
                  <td>
                    <p>{{ formateTime(d.forex_close_time) }} </p>
                  </td>
                </tr>
                <!-- <tr>
                  <th>
                    <p>Mcx Start Trading :</p>
                  </th>
                  <td>
                   <p> {{ formateTime(viewData.mcx_start_trading_at) }} </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>Mcx Stop Trading :</p>
                  </th>
                  <td>
                  <p>  {{ formateTime(viewData.mcx_stop_trading_at) }}</p>
                  </td>
                </tr>
                
                <tr>
                  <th>
                    <p>Equity Start Trading :</p>
                  </th>
                  <td>
                  <p>  {{ formateTime(viewData.equity_futures_start_trading_at) }}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>Equity Stop Trading :</p>
                  </th>
                  <td>
                  <p>  {{ formateTime(viewData.equity_futures_stop_trading_at) }} </p>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { fetch } from "@/api/settings";
import moment from "moment";
import {  resetPendingOrder } from "@/api/pending-order";
import {  carryFroward, generateBill } from "@/api/settings";

export default {
  name: "PendingOrders",
  components: {},
  data() {
    return {
      loading: false,
      viewData: {},
    };
  },
  computed: {},
  created() {
    this.getSettingsDetails();
  },
  methods: {
    formateTime: function (time) {
      return time  ? moment(time, "HH:mm:ss").format("hh:mm A")  : "";
    },
    handleUpdate() {
      return this.$router.push("/super-admin/settings-update");
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getSettingsDetails() {
      this.loading = true;
      fetch().then((response) => {
        this.viewData = response.data.data;
        // this.filters2(this.viewData.mcx_start_trading_at)
        console.log(this.viewData);
        this.loading = false;
      });
    },
    // //////////////////
    // Generate Bill
    // //////////////////
    handleGenerateBill() {
      this.$confirm(
        "Are you sure carry forward is done?",
        "warning",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        generateBill().then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            // this.getList();
            this.loading = false;
          }
        });
      });
    },
    // //////////////////
    // SETTLEMENT
    // //////////////////
    handleSettlement(id) {
      this.$confirm(
        "Are you sure wants to do settlement?",
        "warning",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        resetPendingOrder(id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            // this.getList();
            this.getSettingsDetails();
            this.loading = false;

          }
        });
      });
    },
    // //////////////////
    // RESET
    // //////////////////
    handleCarryForward() {
      this.$confirm(
        "Are you sure wants to do carry forward?",
        "warning",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        carryFroward().then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            // this.getList();
            this.getSettingsDetails();
            this.loading = false;
          }
        });
      });
    },
  },
};
</script>
